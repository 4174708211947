class BackendAuth {
  constructor() {
    this.baseURL = "https://api.spacefarm.ink/api/v1";
    this.secretKey = "210991d7c3bc46c5afedbec6815655c8"; // SECRET из config.py
  }

  // Генерация Proof Payload
  async generatePayload(userId) {
    try {
      const response = await fetch(`${this.baseURL}/generate_payload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }), // Передаем user_id в теле запроса
      });
      const data = await response.json();
      return { tonProof: data.payload }; // Возвращаем payload
    } catch (e) {
      console.error("Ошибка при генерации payload:", e);
    }
  }

  async checkProof(proof, account, userId) {
    try {
      const requestBody = {
        user_id: userId,
        account: {
          address: account.address,
          state_init: account.walletStateInit,
          public_key: account.publicKey,
          chain: account.chain,
        },
        proof: {
          ...proof,
        },
        // address: account.address,
        // network: account.chain,
        // user_id: userId,
        // proof: {
        //   ...proof,
        //   state_init: account.walletStateInit,
        // },
      };

      const response = await fetch(`${this.baseURL}/check_proof`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        console.error("Ошибка проверки Proof:", response.statusText);
        return null;
      }

      const data = await response.json();
      return data?.token; // Возвращаем JWT токен
    } catch (e) {
      console.error("Ошибка в checkProof:", e);
    }
  }

  // Получение информации о пользователе
  async getAccountInfo(jwtSession) {
    try {
      const response = await fetch(`${this.baseURL}/get_account_info`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtSession}`, // Передаем зашифрованную JWT сессию
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error(
          "Ошибка получения информации о пользователе:",
          response.statusText
        );
        return null;
      }

      return await response.json(); // Возвращаем данные о пользователе
    } catch (e) {
      console.error("Ошибка в getAccountInfo:", e);
    }
  }

  async addTokens(token, amount, jwtSession) {
    try {
      const response = await fetch(`${this.baseURL}/add_tokens`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtSession}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, amount }),
      });

      if (!response.ok) {
        console.error("Ошибка при добавлении токенов:", response.statusText);
        return null;
      }

      return await response.json(); // Вернуть ответ от сервера
    } catch (e) {
      console.error("Ошибка в addTokens:", e);
      throw e;
    }
  }

  async createWithdraw(token, amount, jwtSession) {
    try {
      // Проверка типа параметров
      if (typeof token !== "number" || typeof amount !== "number") {
        throw new Error(
          "Invalid parameter type: 'token' and 'amount' must be numbers."
        );
      }

      // Проверка, что jwtSession не пустой
      if (!jwtSession || typeof jwtSession !== "string") {
        throw new Error("Invalid 'jwtSession': must be a non-empty string.");
      }

      // Логируем запрос для отладки
      console.log("Creating withdraw with params:", { token, amount });

      const response = await fetch(`${this.baseURL}/create_withdraw`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtSession}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, amount }),
      });

      // Проверка ответа
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server error:", errorText);
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      // Парсинг ответа
      const data = await response.json();

      // Проверка, что в ответе есть ID вывода
      if (!data || typeof data.id !== "number") {
        throw new Error("Invalid response: 'id' is missing or not a number.");
      }

      console.log("Withdraw created successfully, ID:", data.id);

      return data; // Вернуть ответ (ID вывода)
    } catch (e) {
      console.error("Ошибка в createWithdraw:", e.message || e);
      throw e;
    }
  }

  async verifyWithdraw(withdrawId, code, jwtSession) {
    try {
      // Проверка, что параметры - числа
      if (typeof withdrawId !== "number" || typeof code !== "number") {
        throw new Error(
          "Invalid parameter type: 'withdrawId' and 'code' must be numbers."
        );
      }

      const response = await fetch(`${this.baseURL}/verify_withdraw`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtSession}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ withdraw_id: withdrawId, code: code }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server error:", errorText);
        throw new Error(
          `Server Error ${response.status}: ${response.statusText}`
        );
      }

      return await response.json(); // Вернуть ID транзакции Binance
    } catch (e) {
      console.error("Ошибка в verifyWithdraw:", e);
      throw e;
    }
  }
}
// Экспортируем экземпляр
const backendAuth = new BackendAuth();
export { backendAuth };
